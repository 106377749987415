    ul {
        list-style-type: none;
    }
    
    .navBarUL Link {
        list-style-type: none;
        color: rgb(255, 255, 255);
    }
    
    .active {
        display: block;
    }
    
    .column {
        float: left;
        width: 33.33%;
        padding: 4px;
        background-color: none;
        height: auto;
        display: block;
    }
    
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    
    @media screen and (max-width: 600px) {
        .column {
            width: 100%;
            height: auto;
        }
    }
    
    .navbar-light .navbar-nav .nav-link {
        color: rgb(255, 255, 255);
    }
    
    li .product {
        font-size: 15px;
        font-weight: bolder;
        color: rgb(205, 182, 50);
    }
    
    .hide {
        display: none;
    }
    
    li a:hover+.hide {
        display: block;
    }