.subnavbarLink {
    display: flex;
    color: rgb(250, 250, 250);
    padding: 10px 10px;
    font-size: 1.4vw;
}

nav {
    background-color: rgb(0, 0, 0);
}

.alignment {
    padding-left: 25%;
}

a:hover {
    color: rgb(60, 184, 189);
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .subnavbarLink {
        font-size: small;
    }
    .alignment {
        padding-left: 0%;
    }
}