@font-face {
    font-family: "revicons";
    fallback: fallback;
    src: url("./revicons.woff") format("woff"), url("./revicons.ttf") format("ttf"), url("./revicons.eot") format("ttf");
}
.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
}
.react-multiple-carousel__arrow {
    position: absolute;
    outline: 1;
    transition: all 0.5s;
    border-radius: 10px;
    z-index: 0;
    border: 0.5;
    background: transparent;
    min-width: 22px;
    min-height: 22px;
    opacity: 1;
    cursor: pointer;
}
.react-multiple-carousel__arrow:hover {
    background: transparent;
}
.react-multiple-carousel__arrow::before {
    font-size: 15px;
    color: rgb(1, 1, 15);
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}
.react-multiple-carousel__arrow--left {
    left: calc(-2% + 1.85rem);
}
.react-multiple-carousel__arrow--left::before {
    content: "\e824";
}
.react-multiple-carousel__arrow--right {
    right: calc(-2% + 1.85rem);
}
.react-multiple-carousel__arrow--right::before {
    content: "\e825";
}
.react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.react-multi-carousel-dot button {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background 0.5s;
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;
}
.react-multi-carousel-dot button:hover:active {
    background: #080808;
}
.react-multi-carousel-dot--active button {
    background: #080808;
}
.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-multi-carousel-item {
        flex-shrink: 0 !important;
    }
    .react-multi-carousel-track {
        overflow: visible !important;
    }
}
