    /* GLOBAL STYLES
-------------------------------------------------- */
    /* Padding below the footer and lighter body text */
    
    body {
        padding-top: 3rem;
        padding-bottom: 2rem;
        color: #5a5a5a;
    }
    /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
    /* Carousel base class */
    
    .carousel {
        margin-bottom: 4rem;
    }
    /* Since positioning the image, we need to help out the caption */
    
    .carousel-caption1 {
        bottom: 3rem;
        margin-top: 18rem;
        color: rgb(187, 70, 70);
        /* z-index: 5; */
    }
    /* Declare heights because of positioning of img element */
    
    .carousel-item {
        height: 75vw;
    }
    
    p {
        color: black;
    }
    
    .carousel-item>img {
        position: absolute;
        top: 0;
        left: 0;
        /* min-width: 100%; */
        height: 75vw;
    }
    /* MARKETING CONTENT
  -------------------------------------------------- */
    /* Center align the text within the three columns below the carousel */
    
    .marketing .col-lg-4 {
        margin-bottom: 1.5rem;
        text-align: center;
    }
    
    .marketing h2 {
        font-weight: 400;
    }
    
    .marketing .col-lg-4 p {
        margin-right: .75rem;
        margin-left: .75rem;
    }
    /* Featurettes
  ------------------------- */
    
    .featurette-divider {
        margin: 3rem 0;
        /* Space out the Bootstrap <hr> more */
    }
    /* Thin out the marketing headings */
    
    .featurette-heading {
        /* font-weight: 300; */
        line-height: 1;
        letter-spacing: -.05rem;
        font-family: sans-serif;
    }
    /* RESPONSIVE CSS
  -------------------------------------------------- */
    
    @media (min-width: 40em) {
        /* Bump up size of carousel content */
        .carousel-caption p {
            margin-bottom: 1.25rem;
            font-size: 1.25rem;
            line-height: 1.4;
        }
        .featurette-heading {
            font-size: 45px;
        }
    }
    
    @media (min-width: 62em) {
        .featurette-heading {
            margin-top: 7rem;
        }
        .carousel-item {
            height: 36vw;
        }
        .carousel-item>img {
            position: absolute;
            top: 0;
            left: 0;
            /* min-width: 100%; */
            height: 36vw;
        }
    }
    /* Animation to Carousel */
    
    .bounce-in-top {
        -webkit-animation: bounce-in-top 1s both;
        animation: bounce-in-top 3s both;
    }
    /*animation bounce-in-top
 */
    
    @-webkit-keyframes bounce-in-top {
        0% {
            -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }
        25% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            opacity: 1;
        }
        50% {
            -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        75% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        81% {
            -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }
    
    @keyframes bounce-in-top {
        0% {
            -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 0;
        }
        38% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            opacity: 1;
        }
        55% {
            -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        72% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        81% {
            -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        90% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        95% {
            -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }
    
    .puff-in-center {
        -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
        animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    }
    
    @-webkit-keyframes puff-in-center {
        0% {
            -webkit-transform: scale(2);
            transform: scale(2);
            -webkit-filter: blur(4px);
            filter: blur(4px);
            opacity: 0;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
        }
    }
    
    @keyframes puff-in-center {
        0% {
            -webkit-transform: scale(2);
            transform: scale(2);
            -webkit-filter: blur(4px);
            filter: blur(4px);
            opacity: 0;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
        }
    }
    
    .swing-in-top-fwd {
        -webkit-animation: swing-in-top-fwd 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) 2s infinite both;
        animation: swing-in-top-fwd 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) 2s infinite both;
    }
    
    @-webkit-keyframes swing-in-top-fwd {
        0% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 0;
        }
        100% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 1;
        }
    }
    
    @keyframes swing-in-top-fwd {
        0% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 0;
        }
        100% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
            opacity: 1;
        }
    }
    
    .fade-in-left {
        -webkit-animation: fade-in-left 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2s infinite both;
        animation: fade-in-left 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2s infinite both;
    }
    
    @-webkit-keyframes fade-in-left {
        0% {
            -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    
    @keyframes fade-in-left {
        0% {
            -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    
    .fade-in-right {
        -webkit-animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2s infinite both;
        animation: fade-in-right 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2s infinite both;
    }
    
    @-webkit-keyframes fade-in-right {
        0% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    
    @keyframes fade-in-right {
        0% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    
    a.middle {
        position: relative;
    }
    
    a.middle:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: rgb(2, 117, 138);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }
    
    a.middle:hover:before {
        visibility: visible;
        transform: scaleX(1);
    }
    
    p Link:hover {
        background: linear-gradient( to right, var(--mainColor) 0%, var(--mainColor) 5px, transparent);
    }
    
    .cardcolumn {
        float: left;
        align-items: center;
        width: 30%;
        padding: 10px;
        text-align: center;
        list-style-type: none;
    }
    /* Remove extra left and right margins, due to padding */
    
    .cardrow {
        margin: 0 -5px;
    }
    
    @media screen and (max-width: 600px) {
        .cardcolumn {
            width: 50%;
            display: block;
            margin-bottom: 20px;
        }
    }
    
    .cardrow:after {
        content: "";
        display: table;
        clear: both;
    }
    
    .displaytxt {
        margin-top: 50px;
        width: auto;
        height: 28px;
        background: white;
        transition: width 2s, height 4s;
        overflow: hidden;
    }
    
    .displaytxt:hover {
        width: 100%;
        height: 320px;
    }