.App {
    text-align: center;
    margin-bottom: 50px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #fefeff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    font-family: Arial;
    margin: 0;
}

* {
    box-sizing: border-box;
}

img {
    vertical-align: middle;
}


/* Position the image container (needed to position the left and right arrows) */

.container {
    position: relative;
}


/* Hide the images by default */

.mySlides {
    display: none;
}


/* Add a pointer when hovering over the thumbnail images */

.cursor {
    cursor: pointer;
}


/* Next & previous buttons */

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}


/* Position the "next button" to the right */

.next {
    right: 0;
    border-radius: 2px 0 0 2px;
}

.prev {
    left: 0;
    border-radius: 2px 0 0 2px;
}


/* On hover, add a black background color with a little bit see-through */

.prev:hover,
.next:hover {
    background-color: rgba(189, 181, 181, 0.8);
}


/* Number text (1/3 etc) */

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}


/* Container for image text */

.caption-container {
    text-align: center;
    background-color: rgb(0, 0, 0);
    padding: 5px 16px;
    color: white;
    font-size: large;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}


/* Six columns side by side */

.column {
    float: left;
    width: 16.66%;
}


/* Add a transparency effect for thumnbail images */

.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

.heading {
    width: 100%;
    height: 70px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    padding: 10px 16px;
    align-content: center;
}