* {
    box-sizing: border-box;
}

.img-container {
    position: relative;
    width: 50%;
    max-width: 300px;
}

.image {
    display: block;
    width: 180px;
    height: 50px;
    border-radius: 1.5rem;
}

.column {
    width: 17%;
    padding: 5px;
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
}


/* Clear floats after image containers */

.row::after {
    content: "";
    clear: both;
    display: table;
}

.imgcontainer {
    position: relative;
    width: auto;
    padding-bottom: 20px;
}


/* .overlayimage {
    display: block;
    width: auto;
    height: 100%;
} */

.text {
    color: rgb(0, 0, 0);
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    margin-top: 55px;
}

button {
    margin-top: 5px;
    size: 9px;
}

.prod-heading {
    text-align: center;
    margin-top: 50px;
    /* text-decoration-line: underline; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}